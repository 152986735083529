import * as React from "react"

export const Error = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={182}
        height={43}
        fill="none"
    >
        <g filter="url(#a)" fill="#E7E7E7">
            <path d="m25.562.724-.666 4.583H8.747v13.57H22.78v4.583H8.746v14.233h17.3v4.583H3V.724h22.562ZM47.589 24.666h-6.472v17.61H35.37V.724H46.32c5.201 0 9.133.985 11.795 2.955 2.701 1.97 4.052 4.905 4.052 8.805 0 2.895-.746 5.247-2.238 7.056-1.492 1.81-3.75 3.176-6.774 4.101l11.25 18.635H57.57l-9.98-17.61Zm-.666-4.402c3.025 0 5.303-.603 6.835-1.81 1.533-1.246 2.3-3.236 2.3-5.97 0-2.573-.787-4.423-2.36-5.549-1.532-1.165-4.012-1.748-7.44-1.748h-5.141v15.077h5.806ZM84.212 24.666H77.74v17.61h-5.746V.724h10.948c5.202 0 9.134.985 11.795 2.955 2.702 1.97 4.053 4.905 4.053 8.805 0 2.895-.746 5.247-2.238 7.056-1.492 1.81-3.75 3.176-6.775 4.101l11.251 18.635h-6.835l-9.98-17.61Zm-.665-4.402c3.024 0 5.303-.603 6.835-1.81 1.532-1.246 2.298-3.236 2.298-5.97 0-2.573-.786-4.423-2.359-5.549-1.532-1.165-4.012-1.748-7.44-1.748H77.74v15.077h5.807ZM122.965 0c3.509 0 6.573.844 9.194 2.533 2.662 1.648 4.718 4.08 6.17 7.297 1.492 3.217 2.238 7.117 2.238 11.7 0 4.503-.746 8.363-2.238 11.58-1.452 3.216-3.508 5.668-6.17 7.357-2.621 1.689-5.685 2.533-9.194 2.533-3.508 0-6.593-.824-9.254-2.473-2.621-1.648-4.678-4.08-6.17-7.297-1.452-3.216-2.178-7.096-2.178-11.64 0-4.462.726-8.322 2.178-11.579 1.492-3.256 3.569-5.73 6.23-7.418C116.433.864 119.497 0 122.965 0Zm0 4.704c-3.629 0-6.452 1.367-8.468 4.101s-3.024 6.996-3.024 12.785c0 5.75 1.008 9.971 3.024 12.665 2.057 2.694 4.879 4.04 8.468 4.04 7.662 0 11.493-5.588 11.493-16.765 0-11.217-3.831-16.826-11.493-16.826ZM162.184 24.666h-6.472v17.61h-5.746V.724h10.948c5.202 0 9.134.985 11.795 2.955 2.702 1.97 4.053 4.905 4.053 8.805 0 2.895-.746 5.247-2.238 7.056-1.492 1.81-3.75 3.176-6.775 4.101L179 42.276h-6.835l-9.981-17.61Zm-.665-4.402c3.024 0 5.303-.603 6.835-1.81 1.533-1.246 2.299-3.236 2.299-5.97 0-2.573-.787-4.423-2.359-5.549-1.533-1.165-4.013-1.748-7.44-1.748h-5.142v15.077h5.807Z" />
        </g>
        <defs>
            <filter
                id="a"
                x={0}
                y={0}
                width={182}
                height={43}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx={-3} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.945098 0 0 0 0 0.996078 0 0 0 1 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_269_265" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx={3} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.0862745 0 0 0 1 0" />
                <feBlend
                    in2="effect1_dropShadow_269_265"
                    result="effect2_dropShadow_269_265"
                />
                <feBlend
                    in="SourceGraphic"
                    in2="effect2_dropShadow_269_265"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
)
