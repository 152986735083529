import * as React from "react"
import styled from "styled-components";
import {Error} from "../assets/svg/Error";
import {StaticImage} from "gatsby-plugin-image";
import {ErrorCloud} from "../assets/svg/ErrorCloud";
import '../global-css/global.css'

const NotFound = styled.main`{
  background: #0A0803;
  width: 100%;
  min-height: 100vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
}`

const NotFoundWrapper = styled.section`{
  @media(min-width: 1000px){
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
    justify-content: center;
  }
  margin: auto;
}`

const TextWrapper = styled.div`{
  margin-top: 100px;
  width: 320px;
}`

const Image404Wrapper = styled.div`{
  width: 100%;
  position: relative;
  z-index: 200;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}`

const Image404LayoutWrapper = styled.div`{
  width: 180px;
  z-index: 200;
}`


const ImageWrapper = styled.div`{
  width: 320px;
  position: relative;
  z-index: 100;
}`

const ImageHeroWrapper = styled.div`{
  width: 100%;
  position: relative;
  z-index: 200;
}`

const CloudWrapper = styled.div`{
  position: absolute;
  top: 70px;
  left: 70px;
  z-index: 400;
}`

const ParagraphWrapper = styled.div`{
  position: absolute;
  top: 65px;
  left: 85px;
  z-index: 400;
  width: 100px;
}`

const StyledParagraph = styled.p`{
  font-size: 16px;
  color: #3B3120;
  font-weight: 700;
  font-family: 'Courier Prime Regular';
}`

const ErrorWrapper = styled.div`{
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
}`

const ErrorLayout = styled.div`{
  width: 100%;
  position: relative;
}`

const StripesWrapper = styled.div`{
  width: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
}`




const NotFoundPage = () => {
    return (
        <NotFound>
            <NotFoundWrapper>
                <TextWrapper>
                    <Image404Wrapper>
                        <Image404LayoutWrapper>
                            <StaticImage
                                placeholder={'blurred'}
                                src={`../images/404.png`}
                                alt={'404'}
                                formats={['auto', 'webp', 'avif']}
                                quality={100}
                                layout={'fullWidth'}
                            >
                            </StaticImage>
                        </Image404LayoutWrapper>
                    </Image404Wrapper>
                    <ErrorWrapper>
                        <Error/>
                    </ErrorWrapper>
                </TextWrapper>
                <ImageWrapper>
                    <ImageHeroWrapper>
                        <StaticImage
                            placeholder={'blurred'}
                            src={`../images/hero-404.png`}
                            alt={'404 Hero'}
                            formats={['auto', 'webp', 'avif']}
                            quality={100}
                            layout={'fullWidth'}
                        >
                        </StaticImage>
                        <CloudWrapper>
                            <ErrorCloud/>
                            <StripesWrapper>
                                <StaticImage
                                    placeholder={'blurred'}
                                    src={`../images/second-stripes-back.png`}
                                    alt={''}
                                    formats={['auto', 'webp', 'avif']}
                                    quality={100}
                                    layout={'fullWidth'}
                                >
                                </StaticImage>
                            </StripesWrapper>
                        </CloudWrapper>
                        <ParagraphWrapper>
                            <StyledParagraph>
                                OOPS!
                            </StyledParagraph>
                        </ParagraphWrapper>
                    </ImageHeroWrapper>
                </ImageWrapper>
            </NotFoundWrapper>
        </NotFound>
    )
}

export default NotFoundPage
