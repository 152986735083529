import * as React from "react"

export const ErrorCloud = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={79}
        height={43}
        fill="none"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M74.455 35.116c-1.785 0-2.912 1.92-2.041 3.478 1.2 2.149-1.306 4.455-3.347 3.08l-8.983-6.047a3 3 0 0 0-1.675-.511H3.78a3 3 0 0 1-3-3V3.363a3 3 0 0 1 3-3h71.97a3 3 0 0 1 3 3v28.753a3 3 0 0 1-3 3h-1.296Z"
            fill="#FFA116"
        />
    </svg>
)